import React from 'react'
import { Loader } from '../commonComponents'
const ResourceForm = ({formTag,title,isFormLoaded}) => {

  return (
    <div className="tw-form-get-ebook tw-h-auto tw-overflow-y-auto tw-w-auto lg:tw-w-[450px] tw-bg-transparent tw-p-0 tw-mx-[6px]">
      {title &&  isFormLoaded && (
        <h5 className="title title-5"> {title}</h5>
        )}
        {!isFormLoaded &&
          <div>
           <Loader/>
          </div>
        }
        <div className={`${!isFormLoaded ? "unset-display":"set-display"}`}>
          {formTag}
        </div>

    </div>
  )
}

export default ResourceForm